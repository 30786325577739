import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';

import NotFoundComponent from '../components/layout/not-found'

const NotAuthorizedPage = () => (
  <Layout>
    <SEO title="403: Not Authorized" />
    <NotFoundComponent type={403} />
  </Layout>
);

export default NotAuthorizedPage;
